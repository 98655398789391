import { useState, useEffect } from 'react';

const useFullScreen = (ref) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreenError, setFullScreenError] = useState(null);

  const setFullScreen = () => {
    if (ref.current && !document.fullscreenElement) {
      ref.current
        .requestFullscreen()
        .then(() => {
          setIsFullScreen(true);
          setFullScreenError(null);
        })
        .catch((error) => {
          setFullScreenError(error);
        });
    }
  };

  const exitFullScreen = () => {
    if (ref.current && document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullScreen(false);
          setFullScreenError(null);
        })
        .catch((error) => {
          setFullScreenError(error);
        });
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('fullscreenchange', () => {
        if (document.fullscreenElement) {
          setIsFullScreen(true);
        } else {
          setIsFullScreen(false);
        }
      });
    }
  }, [ref]);

  return [isFullScreen, fullScreenError, setFullScreen, exitFullScreen];
};

export default useFullScreen;
