import styled from 'styled-components';

import { accentColor, textColors, fontWeight, fontSize } from '../../constants';

export const Container = styled.span`
  padding: 1.5rem 2.5rem;

  background-color: ${(props) => {
    return `${props.isDisabled ? accentColor.subLight : accentColor.sub};`;
  }};

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => {
    return `${props.isDisabled ? 'not-allowed' : 'pointer'};`;
  }};
  border-radius: 0.8rem;
  align-self: flex-end;

  & > p {
    font-size: ${fontSize.mediumDefault};
    font-weight: ${fontWeight.bold};
    color: ${textColors.textLight};
  }
`;
