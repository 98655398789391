import styled from 'styled-components';

import { backgroundColor, textColors, fontSize, fontWeight, borderColor } from '../../shared/constants';

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;

  background-color: ${backgroundColor.light};
`;
export const Header = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  padding: 0 3.5rem 0 3rem;
  align-items: center;
  justify-content: space-between;
  background-color: ${backgroundColor.headerBackgroundLight};
  border-bottom: solid 0.1rem ${borderColor.medium};
`;

export const HeaderTextContainer = styled.div`
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
`;

export const HeaderText = styled.p`
  color: ${textColors.textDark};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.titleVerySmall};
  margin-bottom: 0;
`;

export const HeaderTextSub = styled.p`
  color: ${textColors.textGray};
  font-size: ${fontSize.titleVerySmall};
`;

export const TimeContainer = styled.div`
  width: 33%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TimeItem = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  & > p {
    font-size: ${fontSize.titleVerySmall};
    color: ${textColors.textDark};
    margin-bottom: 0;

    &:nth-child(2) {
      font-weight: ${fontWeight.bold};
    }
  }

  & > span {
    display: block;
    height: 0.5rem;
    width: 100%;
    border-radius: 0.2rem;
    background-color: ${backgroundColor.light};

    &::after {
      content: '';
      display: block;
      width: ${(props) => {
        if (props.percentage) {
          return `${props.percentage}%;`;
        }

        return '0;';
      }};
      height: 0.5rem;
      border-radius: 0.2rem;
      background-color: ${backgroundColor.success};
    }
  }
`;

export const SubmitButtonContainer = styled.div`
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const SubmitButton = styled.div`
  height: 100%;
  display: flex;

  & > * {
    align-self: center;
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  height: calc(100vh - 7rem);
  display: flex;
`;

export const ListContainer = styled.div`
  width: 15%;
  height: 100%;
`;

export const QuestionContainer = styled.div`
  width: 42%;
  height: 100%;
`;

export const AnswerContainer = styled.div`
  width: 43%;
  height: 100%;
`;

export const PopUp = {
  Container: styled.div`
    width: 100vw;
    height: 100vh;

    position: absolute;
    left: 0;

    background-color: ${backgroundColor.transparent};

    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Warning: {
    Container: styled.div`
      width: 40%;
      height: 50%;

      display: flex;
      flex-direction: column;

      border: 0.1rem solid ${backgroundColor.warning};

      border-radius: 0.5rem;

      overflow: hidden;
    `,
    Message: styled.div`
      width: 100%;
      height: 7rem;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: ${backgroundColor.warning};

      & > p {
        color: ${textColors.textLight};
        font-size: ${fontSize.titleSize};
        font-weight: ${fontWeight.bold};
      }
    `,
    Info: {
      Container: styled.div`
        width: 100%;
        height: calc(100% - 7rem);

        display: flex;
        flex-direction: column;

        background-color: ${backgroundColor.light};
      `,
      Main: styled.div`
        width: 100%;
        padding: 2rem 0;

        display: flex;
        justify-content: center;
        align-items: center;

        & > p {
          color: ${backgroundColor.warning};
          font-size: ${fontSize.titleSize};
          font-weight: ${fontWeight.bold};
        }
      `,
      Title: styled.div`
        width: 100%;
        padding: 0.5rem 0;

        display: flex;
        justify-content: center;
        align-items: center;

        & > p {
          color: ${textColors.textDark};
          font-size: ${fontSize.mediumDefault};
          font-weight: ${fontWeight.bold};
        }
      `,
      Action: {
        Container: styled.div`
          width: 100%;
          padding: 1rem 0;

          align-self: flex-end;

          display: flex;
          justify-content: center;
          align-items: center;
        `,
        Button: styled.span`
          padding: 1rem 1.5rem;

          border-radius: 0.5rem;

          background-color: ${backgroundColor.warning};
          color: ${textColors.textLight};
          font-size: ${fontSize.mediumDefault};
          font-weight: ${fontWeight.bold};

          cursor: pointer;
        `
      }
    }
  }
};
