import styled from 'styled-components';

import { backgroundColor, textColors, borderColor, accentColor, fontSize } from '../../shared/constants';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.5rem 2rem;
`;

export const QuestionListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 1rem 0;
`;

export const QuestionItem = styled.div`
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s;

  font-size: ${fontSize.mediumDefault};

  &:hover {
    color: ${accentColor.sub};
    background-color: ${backgroundColor.light};
    border: solid 0.2rem ${accentColor.sub};
    transform: scale(1.01);
  }

  color: ${(props) => {
    if (props.type === 'selected') {
      return accentColor.sub;
    }

    if (props.type === 'submitted') {
      return textColors.textLight;
    }

    return textColors.textGray;
  }};

  background-color: ${(props) => {
    if (props.type === 'submitted') {
      return accentColor.sub;
    }

    if (props.type === 'selected') {
      return backgroundColor.selectedOption;
    }

    return backgroundColor.light;
  }};

  border: ${(props) => {
    if (props.type === 'submitted' || props.type === 'selected') {
      return `solid 0.2rem ${accentColor.sub}`;
    }
    return `solid 0.2rem ${borderColor.codeHeader}`;
  }};
`;
