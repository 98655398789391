import React from 'react';
import styled from 'styled-components';

import Loading from '../../assets/Loading.svg';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  z-index: 15;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  width: 10rem;
  display: inline-block;
  z-index: 20;
`;

const LoadingComponent = () => {
  return (
    <Container>
      <Icon src={Loading} />
    </Container>
  );
};

export default LoadingComponent;
