import React, { useEffect, useState } from 'react';

import ButtonComponent from '../../shared/components/ButtonComponent/Button.component';

import {
  Container,
  Header,
  AnswerContainer,
  DecisionContainer,
  AnswerValueContainer,
  AnswerItem,
  ButtonContainer
} from './QuizExamAnswer.styles';

const QuizExamAnswer = (props) => {
  // functions
  const { moveForward } = props;
  // variables
  const { userAnswer, question, questionType, answerList, selectedQuestionIndex, questionListLength } = props;

  const [userAnswerLocal, setUserAnswerLocal] = useState([]);

  const checkIfSelected = (option) => userAnswerLocal.includes(option);

  const selectOption = (option) => {
    if (!checkIfSelected(option)) {
      if (questionType === 'multiple') {
        setUserAnswerLocal([...userAnswerLocal, option]);
      } else {
        setUserAnswerLocal(new Array(option));
      }
    } else {
      setUserAnswerLocal(userAnswerLocal.filter((item) => item !== option));
    }
  };

  useEffect(() => {
    setUserAnswerLocal(userAnswer || []);
  }, [userAnswer, question]);

  return (
    <Container>
      <Header>
        <p>{`Choose the correct option${questionType === 'multiple' ? 's' : ''}`}</p>
      </Header>
      <AnswerContainer>
        <AnswerValueContainer>
          {answerList.map((item, index) => {
            return (
              <AnswerItem
                key={`${index}{${Math.random()}}`}
                isSelected={`${checkIfSelected(item)}`}
                onClick={() => selectOption(item)}
              >
                <span>{index + 1}</span>
                <div>{`${item}`}</div>
              </AnswerItem>
            );
          })}
        </AnswerValueContainer>
        <DecisionContainer>
          <ButtonContainer>
            <ButtonComponent
              buttonText={selectedQuestionIndex < questionListLength - 1 ? 'Save and Next' : 'Save'}
              buttonClick={() => moveForward(userAnswerLocal)}
            />
          </ButtonContainer>
        </DecisionContainer>
      </AnswerContainer>
    </Container>
  );
};

export default QuizExamAnswer;
