import { createGlobalStyle } from 'styled-components';
import { backgroundColor } from '../constants';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: 'Roboto', sans-serif;

    scrollbar-width: thin;
    scrollbar-color: ${backgroundColor.scrollBar} ${backgroundColor.cardBackground};

    &::-webkit-scrollbar {
      width: 0.5rem;
      
    }

    &::-webkit-scrollbar-track {
      background: ${backgroundColor.cardBackground};
    }
    &::-webkit-scrollbar-thumb {
      
      border-radius: 3rem;
      border: 0.1rem solid ${backgroundColor.cardBackground};
      background-color: ${backgroundColor.scrollBar};
    }
  }

  p {
    user-select: none;
  }
`;

export default GlobalStyle;
