import React from 'react';
import { connect } from 'react-redux';

import { Container, QuestionListContainer, QuestionItem } from './QuizExamList.styles';

import { Creators } from './store/action';

class QuizExamList extends React.Component {
  onQuestionNoClick = (selectedQuestion, selectedQuestionIndex) => {
    const { onChangeQuestionToSelectedHandler } = this.props;
    const payload = {
      selectedQuestion,
      selectedQuestionIndex
    };
    onChangeQuestionToSelectedHandler(payload);
  };

  checkStatus = (questionID, questionSubmitted) => {
    const { selectedQuestion } = this.props;

    const isSelected = selectedQuestion.questionID === questionID;

    if (questionSubmitted && !isSelected) {
      return 'submitted';
    }

    if (isSelected) {
      return 'selected';
    }

    return '';
  };

  render() {
    const { questionList } = this.props;

    return (
      <Container>
        <QuestionListContainer>
          {questionList.map((question, key) => {
            const { questionSubmitted, questionID } = question;
            const uniqueKey = `${key}${questionID}`;
            return (
              <QuestionItem
                key={uniqueKey}
                type={this.checkStatus(questionID, questionSubmitted)}
                onClick={() => this.onQuestionNoClick(question, parseInt(key))}
              >
                {parseInt(key) + 1}
              </QuestionItem>
            );
          })}
        </QuestionListContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  questionList: state.quizExam.questionList,
  selectedQuestion: state.quizExam.selectedQuestion,
  selectedQuestionIndex: state.quizExam.selectedQuestionIndex
});

const mapDispatchToProps = (dispatch) => ({
  onChangeQuestionToSelectedHandler: (payload) => dispatch(Creators.onChangeQuestionToSelected(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizExamList);
