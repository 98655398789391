import React from 'react';

import { Container } from './Button.styles';

class ButtonComponent extends React.Component {
  buttonClick = () => {
    this.props.buttonClick();
  };

  render() {
    const { buttonText, isDisabled } = this.props;
    return (
      <Container onClick={() => !isDisabled && this.buttonClick()} isDisabled={isDisabled}>
        <p>{buttonText}</p>
      </Container>
    );
  }
}

export default ButtonComponent;
