import styled from 'styled-components';

import { backgroundColor, textColors, fontSize, fontWeight, accentColor } from '../../shared/constants';

export const Body = {
  Container: styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${backgroundColor.mediumLight};
  `
};

export const Message = {
  Container: styled.div`
    width: 50%;
    height: 80%;

    border-radius: 1.5rem;

    display: flex;
    flex-direction: column;

    & > * {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    padding: 2rem;

    background-color: ${backgroundColor.light};
  `
};

export const MessageCoverContainer = styled.div`
  width: 100%;
  height: 100%;

  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  padding: 0 2rem;

  & > * {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
`;

export const Header = {
  Container: styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
  Icon: styled.div`
    width: 20rem;

    margin-bottom: 1rem;
    & > img {
      width: 100%;
    }
  `,
  Title: styled.h1`
    color: ${textColors.textGrayDark};
    font-size: ${fontSize.titleVerySmall};
    font-weight: ${fontWeight.bold};
  `
};

export const Info = {
  Container: styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
  `,
  Item: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 50%;

    & > span {
      font-size: ${fontSize.mediumDefault};
      font-weight: ${fontWeight.bold};

      &:nth-child(1) {
        color: ${textColors.textGray};
        margin-right: 1rem;
      }
      &:nth-child(2) {
        color: ${textColors.textGrayDark};
      }
    }
  `
};

export const RulesDummyContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const Rules = {
  Container: styled.div`
    width: 100%;
    height: 100%;
    postion: relative;

    display: flex;
    flex-direction: column;
  `,
  Title: {
    Container: styled.div`
      width: 100%;

      display: flex;
      justify-content: flex-start;
    `,
    Value: styled.h4`
      font-size: ${fontSize.mediumDefault};
      font-weight: ${fontWeight.bold};
      color: ${textColors.textGrayDark};
    `,
    Item: styled.div`
      padding: 1rem 0rem;

      min-width: 6rem;

      display: flex;
      justify-content: center;

      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;

      & > span {
        color: ${accentColor.sub};
        font-size: ${fontSize.titleVerySmall};
        font-weight: ${fontWeight.bold};
      }
    `
  },
  Scroll: {
    Container: styled.div`
      width: 100%;

      display: flex;
      flex-direction: column;
    `,
    Item: styled.div`
      width: 100%;

      padding: 0.8rem 0;

      display: flex;
      justify-content: flex-start;

      & > span {
        font-size: ${fontSize.title};
        font-weight: ${fontWeight.bold};

        &:nth-child(1) {
          color: ${textColors.textGrayDark};
          margin-right: 1rem;
        }
        &:nth-child(2) {
          color: ${textColors.textGrayDark};
        }
      }
    `
  }
};

export const Action = {
  Container: styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > * {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    & > div {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;

        cursor: pointer;
        & > input {
          margin-right: 1rem;
        }

        & > span {
          font-size: ${fontSize.mediumDefault};
          font-weight: ${fontWeight.bold};
          color: ${textColors.textGrayDark};
        }
      }
    }
  `
};

/* export const Error = {
  Container: styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;

    & > * {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  `,
  Header: styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    & > span {
      font-size: ${fontSize.titleMedium};
      font-weight: ${fontWeight.bold};
      color: ${accentColor.main};
    }
  `,
  Message: styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    & > span {
      font-size: ${fontSize.mediumDefault};
      font-weight: ${fontWeight.medium};
      color: ${textColors.textGrayDark};
    }
  `
}; */

export const Error = {
  Container: styled.div`
    width: 40%;
    height: 50%;

    display: flex;
    flex-direction: column;

    border: 0.1rem solid ${accentColor.main};

    border-radius: 0.5rem;

    overflow: hidden;
  `,
  Message: styled.div`
    width: 100%;
    height: 7rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${accentColor.main};

    & > p {
      color: ${textColors.textLight};
      font-size: ${fontSize.titleSize};
      font-weight: ${fontWeight.bold};
    }
  `,
  Info: {
    Container: styled.div`
      width: 100%;
      height: calc(100% - 7rem);

      display: flex;
      flex-direction: column;
    `,
    Main: styled.div`
      width: 100%;
      padding: 2rem 0;

      display: flex;
      justify-content: center;
      align-items: center;

      & > p {
        color: ${accentColor.main};
        font-size: ${fontSize.titleSize};
        font-weight: ${fontWeight.bold};
      }
    `,
    Title: styled.div`
      width: 100%;
      padding: 0.5rem 0;

      display: flex;
      justify-content: center;
      align-items: center;

      & > p {
        color: ${textColors.textDark};
        font-size: ${fontSize.mediumDefault};
        font-weight: ${fontWeight.bold};
      }
    `,
    Message: styled.div`
      width: 100%;

      padding: 0.5rem;

      align-self: flex-end;

      display: flex;
      justify-content: center;
      align-items: center;

      & > span {
        font-size: ${fontSize.mediumDefault};
        font-weight: ${fontWeight.medium};
        color: ${textColors.textGrayDark};

        margin-right: 0.5rem;
      }

      & > a {
        font-size: ${fontSize.mediumDefault};
        font-weight: ${fontWeight.medium};
      }
    `
  }
};

export const Success = {
  Container: styled.div`
    width: 40%;
    height: 50%;

    display: flex;
    flex-direction: column;

    border: 0.1rem solid ${backgroundColor.success};

    border-radius: 0.5rem;

    overflow: hidden;
  `,
  Message: styled.div`
    width: 100%;
    height: 7rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${backgroundColor.success};

    & > p {
      color: ${textColors.textLight};
      font-size: ${fontSize.titleSize};
      font-weight: ${fontWeight.bold};
    }
  `,
  Info: {
    Container: styled.div`
      width: 100%;
      height: calc(100% - 7rem);

      display: flex;
      flex-direction: column;
    `,
    Main: styled.div`
      width: 100%;
      padding: 2rem 0;

      display: flex;
      justify-content: center;
      align-items: center;

      & > p {
        color: ${backgroundColor.success};
        font-size: ${fontSize.titleSize};
        font-weight: ${fontWeight.bold};
      }
    `,
    Title: styled.div`
      width: 100%;
      padding: 0.5rem 0;

      display: flex;
      justify-content: center;
      align-items: center;

      & > p {
        color: ${textColors.textDark};
        font-size: ${fontSize.mediumDefault};
        font-weight: ${fontWeight.bold};
      }
    `,
    Message: styled.div`
      width: 100%;

      padding: 0.5rem;

      align-self: flex-end;

      display: flex;
      justify-content: center;
      align-items: center;

      & > span {
        font-size: ${fontSize.mediumDefault};
        font-weight: ${fontWeight.medium};
        color: ${textColors.textGrayDark};

        margin-right: 0.5rem;
      }

      & > a {
        font-size: ${fontSize.mediumDefault};
        font-weight: ${fontWeight.medium};
      }
    `
  }
};
