import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  saveResponse: ['payload'],
  saveResponseStart: null,
  saveResponseSuccess: ['payload'],
  saveResponseFailure: ['payload'],

  submitQuiz: ['payload'],
  submitQuizStart: null,
  submitQuizSuccess: ['payload'],
  submitQuizFailure: ['payload'],

  startQuiz: ['payload'],
  startQuizStart: null,
  startQuizSuccess: ['payload'],
  startQuizFailure: ['payload'],

  onChangeQuestionToSelected: ['payload'],

  saveTimeProgress: ['payload'],
  //--------------------------------------
  getCredential: ['payload'],
  getCredentialStart: null,
  getCredentialSuccess: ['payload'],
  getCredentialFailure: ['payload'],

  getQuizDetail: null,
  getQuizDetailStart: null,
  getQuizDetailSuccess: ['payload'],
  getQuizDetailFailure: ['payload'],

  getQuestionList: ['payload'],
  getQuestionListStart: null,
  getQuestionListSuccess: ['payload'],
  getQuestionListFailure: ['payload'],

  setError: ['payload'],
  setIsQuizStarted: ['payload']
});

export { Types, Creators };
