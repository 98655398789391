import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { errorLevelString, errorTypeString } from '../../shared/constants';

import PanorbitLogo from '../../shared/assets/panorbit.png';

import {
  Body,
  Message,
  MessageCoverContainer,
  Header,
  Info,
  Rules,
  Action,
  RulesDummyContainer,
  Error,
  Success
} from './MessageDisplay.styles';

import ButtonComponent from '../../shared/components/ButtonComponent/Button.component';
import { contactMail } from '../../shared/constants/string';

const { Container: BodyContainer } = Body;
const { Container: MessageContainer } = Message;
const { Container: HeaderContainer, Title: HeaderTitle, Icon: HeaderIconContainer } = Header;
const { Container: InfoContainer, Item: InfoItem } = Info;
const {
  Container: RulesContainer,
  Title: { Container: RulesTitleContainer, Item: RulesTitleItem },
  Scroll: { Container: RulesScrollContainer, Item: RulesScrollItem }
} = Rules;
const { Container: ActionContainer } = Action;
const {
  Container: ErrorContainer,
  Message: ErrorMessage,
  Info: {
    Container: ErrorInfoContainer,
    Main: ErrorInfoMainContainer,
    Title: ErrorTitleContainer,
    Message: ErrorMessageContainer
  }
} = Error;

const {
  Container: SuccessContainer,
  Message: SuccessMessage,
  Info: {
    Container: SuccessInfoContainer,
    Main: SuccessInfoMainContainer,
    Title: SuccessTitleContainer,
    Message: SuccessMessageContainer
  }
} = Success;

const RULES = [
  'You can attempt the test only once',
  'Make sure you have a good internet connection',
  'Once the start button is clicked, you cannot pause',
  'Once the start button is clicked, the timer will be on and the form will automatically shuts down once the time is up',
  'Do NOT use mobile/tablet to take this test',
  'Switching tabs/exit/escape/reloading will submit the test'
];

const MessageDisplayView = (props) => {
  const [isInstructionsRead, setIsInstructionsRead] = useState(false);

  // functions
  const { submitAction } = props;

  // variables
  const { fullName, email, quizDetail, error, quizResult, errorLevel } = props;

  const getContactMessage = (message, email) => {
    return (
      <>
        <span>{message}</span>
        <a href={`mailto: ${email}`}>{email}</a>
      </>
    );
  };

  const _renderQuizRules = (questionCount, duration) => {
    const dynamicRules = [
      {
        id: 100,
        value: `There are total ${questionCount} questions`
      },
      {
        id: 101,
        value: `The duration of the test is ${duration} minutes`
      }
    ];

    return [
      ...dynamicRules.map((rule, index) => {
        const { value, id } = rule;
        return (
          <RulesScrollItem key={`${id}`}>
            <span>{`${index + 1})`}</span>
            <span>{value}</span>
          </RulesScrollItem>
        );
      }),
      ...RULES.map((item, index) => (
        <RulesScrollItem key={`${index}`}>
          <span>{`${index + dynamicRules.length + 1})`}</span>
          <span>{item}</span>
        </RulesScrollItem>
      ))
    ];
  };

  const _renderQuizInformation = () => (
    <>
      <InfoContainer>
        <InfoItem>
          <span>Name : </span>
          <span>{fullName}</span>
        </InfoItem>
        <InfoItem>
          <span>E-Mail : </span>
          <span>{email}</span>
        </InfoItem>
      </InfoContainer>
      <InfoContainer>
        <InfoItem>
          <span>Total Questions : </span>
          <span>{quizDetail?.questionCount || 'N/A'}</span>
        </InfoItem>
        <InfoItem>
          <span>Due Date : </span>
          <span>{moment(quizDetail?.dueDate || 'N/A').format('DD MMMM	YYYY')}</span>
        </InfoItem>
      </InfoContainer>
      <InfoContainer>
        <InfoItem>
          <span>Duration : </span>
          <span>{`${quizDetail?.remainingTime || 'N/A'} Minutes`}</span>
        </InfoItem>
      </InfoContainer>
      <RulesDummyContainer>
        <RulesContainer>
          <RulesTitleContainer>
            <RulesTitleItem>
              <span>Instructions</span>
            </RulesTitleItem>
          </RulesTitleContainer>
          <RulesScrollContainer>
            {_renderQuizRules(quizDetail?.questionCount || 'N/A', quizDetail?.remainingTime || 'N/A')}
          </RulesScrollContainer>
        </RulesContainer>
      </RulesDummyContainer>

      <ActionContainer>
        <div>
          <div
            onClick={() => {
              setIsInstructionsRead(!isInstructionsRead);
            }}
          >
            <input type='checkbox' checked={isInstructionsRead} onChange={() => {}} />
            <span>I have read all the Instructions</span>
          </div>
        </div>
        <div>
          <ButtonComponent
            buttonText={'Start Aptitude Test'}
            buttonClick={() => submitAction()}
            isDisabled={!isInstructionsRead}
          />
        </div>
      </ActionContainer>
    </>
  );

  const _renderError = () => {
    const { type } = error;

    let errorStringToDisplay = '';
    let errorMessage = '';
    let contactMessage = '';

    if (errorTypeString.focusLostError === type) {
      errorStringToDisplay = 'Screen Changed';
      errorMessage = 'Aptitude test has been submitted';
      contactMessage = getContactMessage('If you think this is a mistake. Let us know at', contactMail);
    }

    if (errorTypeString.fullScreenError === type) {
      errorStringToDisplay = 'Full Screen closed';
      errorMessage = 'Aptitude test has been submitted';
      contactMessage = getContactMessage('If you think this is a mistake. Let us know at', contactMail);
    }

    if (errorTypeString.invalidUserError === type) {
      errorStringToDisplay = 'Invalid User';
      errorMessage = 'URL is either Wrong or Expired';
      contactMessage = getContactMessage('Contact us at', contactMail);
    }

    if (errorTypeString.invalidQuizError === type) {
      errorStringToDisplay = 'Aptitude Expired';
      errorMessage = 'URL is either Wrong or Expired';
      contactMessage = getContactMessage('If you think this is a mistake. Let us know at', contactMail);
    }

    if (errorTypeString.reloadQuizError === type) {
      errorStringToDisplay = 'Quiz Reloaded';
      errorMessage = 'Page Reloaded, Aptitude test has been submitted';
      contactMessage = getContactMessage('If you think this is a mistake. Let us know at', contactMail);
    }

    return (
      <ErrorContainer>
        <ErrorMessage>
          <p>Error</p>
        </ErrorMessage>
        <ErrorInfoContainer>
          <ErrorInfoMainContainer>
            <p>{errorStringToDisplay}</p>
          </ErrorInfoMainContainer>
          <ErrorTitleContainer>
            <p>{`Panorbit Aptitude Test`}</p>
          </ErrorTitleContainer>
          <ErrorMessageContainer>
            <span>{errorMessage}</span>
          </ErrorMessageContainer>
          <ErrorMessageContainer>{contactMessage}</ErrorMessageContainer>
        </ErrorInfoContainer>
      </ErrorContainer>
    );
  };

  const _renderSuccess = () => {
    return (
      <SuccessContainer>
        <SuccessMessage>
          <p>Success</p>
        </SuccessMessage>
        <SuccessInfoContainer>
          <SuccessInfoMainContainer>
            <p>{`Response Submitted`}</p>
          </SuccessInfoMainContainer>
          <SuccessTitleContainer>
            <p>{`Panorbit Aptitude Test`}</p>
          </SuccessTitleContainer>
          <SuccessMessageContainer>
            <span>{`Someone at Panorbit will contact you soon`}</span>
          </SuccessMessageContainer>
          <SuccessMessageContainer>
            {getContactMessage('In case of queries, write us at', contactMail)}
          </SuccessMessageContainer>
        </SuccessInfoContainer>
      </SuccessContainer>
    );
  };

  if (errorLevel === errorLevelString.major) {
    return <BodyContainer>{_renderError()}</BodyContainer>;
  }

  if (quizResult) {
    return <BodyContainer>{_renderSuccess()}</BodyContainer>;
  }

  return (
    <BodyContainer>
      <MessageContainer>
        <MessageCoverContainer>
          <HeaderContainer>
            <HeaderIconContainer>
              <img src={PanorbitLogo} alt='Logo' />
            </HeaderIconContainer>
            <HeaderTitle>Aptitude Test</HeaderTitle>
          </HeaderContainer>
          {_renderQuizInformation()}
        </MessageCoverContainer>
      </MessageContainer>
    </BodyContainer>
  );
};

const mapStateToProps = (state) => ({
  fullName: state.quizExam.fullName,
  email: state.quizExam.email,
  quizDetail: state.quizExam.quizDetail,
  quizResult: state.quizExam.quizResult
});

export default connect(mapStateToProps)(MessageDisplayView);
