export const errorTypeString = {
  fullScreenError: 'full-screen',
  focusLostError: 'focus-lost',
  invalidUserError: 'invalid-user',
  internetError: 'internet',
  invalidQuizError: 'invalid-quiz',
  reloadQuizError: 'reload-quiz'
};

export const errorLevelString = {
  major: 'major',
  minor: 'minor'
};

export const contactMail = 'career@panorbit.in';
