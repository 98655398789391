import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useHotkeys } from 'react-hotkeys-hook';

import { errorTypeString, errorLevelString } from './shared/constants';

import MessageDisplayView from './views/MessageDisplayView/MessageDisplay.view';
import QuizExamView from './views/QuizExamView/QuizExam.view';
import LoadingComponent from './shared/components/LoadingComponent/Loading.component';

import { Creators as QuizExamCreators } from './views/QuizExamView/store';

import useScreenVisibility from './shared/hooks/useScreenVisibility';
import useFullScreen from './shared/hooks/useFullScreen';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

function App(props) {
  //functions
  const { getCredential, getQuizDetail, getQuestionList, setError, submitQuiz, setIsQuizStarted, startQuiz } = props;
  //const { getCredential, getQuizDetail, getQuestionList, setError, submitQuiz, /* setIsQuizStarted, */ startQuiz } = props;

  //variables
  const { accessToken, loading, quizDetail, questionList, error, quizResult, isQuizStarted, errorLevel } = props;
  //const { accessToken, loading, quizDetail, questionList, error, quizResult, /* isQuizStarted, */ errorLevel } = props;

  // eslint-disable-next-line
  const [verificationPin, setVerificationPin] = useState(null);
  // dont remove this, need this for testing
  //const [isQuizStarted, setIsQuizStarted] = useState(false);

  // ref
  const ContainerRef = useRef(null);
  const isQuizStartedRef = useRef(isQuizStarted);

  // custom hooks
  const isScreenVisible = useScreenVisibility();
  // eslint-disable-next-line
  const [isFullScreen, fullScreenError, setFullScreen, exitFullScreen] = useFullScreen(ContainerRef);

  const clearError = () => {
    const payload = {
      error: null,
      errorLevel: errorLevel
    };

    setError(payload);
  };

  const getQuestionListLocal = () => {
    const payload = {
      quizId: quizDetail?.quizId || ''
    };
    getQuestionList(payload);
  };

  const submitQuizLocal = () => {
    const { quizId } = quizDetail;

    const payload = {
      quizId
    };

    submitQuiz(payload);
  };

  //generic

  useHotkeys('f5', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  // windows

  useHotkeys('ctrl+r', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+f', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+o', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+u', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+t', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+w', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+s', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+d', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+g', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+h', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+j', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+n', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl+a', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('alt+1', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('alt+2', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('alt+3', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('alt+4', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('alt+5', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('alt+6', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('alt+7', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('alt+8', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('alt+9', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('alt+0', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl + shift + r', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('ctrl + shift + i', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  // mac

  useHotkeys('command+r', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+f', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+o', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+u', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+t', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+w', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+s', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+d', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+g', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+h', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+j', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+n', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+a', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+1', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+2', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+3', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+4', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+5', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+6', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+7', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+8', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+9', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+0', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  useHotkeys('command+shift+r', (event) => {
    isQuizStartedRef.current && event.preventDefault();
  });

  // checks if hashId is present in url and sets value based on it
  useEffect(() => {
    const verificationPin = window.location.search.split('=')[1];
    if (verificationPin === undefined || verificationPin === '') {
      const payload = {
        error: {
          type: errorTypeString.invalidUserError
        },
        errorLevel: errorLevelString.major
      };
      setError(payload);
    } else {
      setVerificationPin(verificationPin);

      let payload = {
        verificationPin
      };

      getCredential(payload);

      payload = {
        error: null,
        errorLevel: null
      };

      setError(payload);
    }

    window.addEventListener('contextmenu', (event) => isQuizStartedRef.current && event.preventDefault());

    return () => window.removeEventListener('contextmenu', () => {});

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (accessToken) getQuizDetail();
  }, [accessToken, getQuizDetail]);

  // checks if screenIsVisible and sets error
  useEffect(() => {
    if (!isScreenVisible && isQuizStarted && errorLevel !== errorLevelString.major) {
      const payload = {
        error: {
          type: errorTypeString.focusLostError
        },
        errorLevel: errorLevel === errorLevelString.minor ? errorLevelString.major : errorLevelString.minor
      };
      setError(payload);
    }
    // eslint-disable-next-line
  }, [isQuizStarted, isScreenVisible, setError]);

  // checks if full screen is left
  useEffect(() => {
    if (!isFullScreen && isQuizStarted && errorLevel !== errorLevelString.major) {
      const payload = {
        error: {
          type: errorTypeString.fullScreenError
        },
        errorLevel: errorLevel === errorLevelString.minor ? errorLevelString.major : errorLevelString.minor
      };

      setError(payload);
    }
    // eslint-disable-next-line
  }, [isQuizStarted, isFullScreen, setError]);

  // this effect runs when question list is loaded
  useEffect(() => {
    if (questionList.length && !isQuizStarted) {
      setFullScreen();
      const { quizId, remainingTime } = quizDetail;
      const payload = {
        quizId,
        remainingTime
      };

      startQuiz(payload);
    }
    // eslint-disable-next-line
  }, [questionList]);

  // this effect runs when an error of level major is triggered
  useEffect(() => {
    if (errorLevel === errorLevelString.major) {
      const payload = {
        isQuizStarted: false
      };
      setIsQuizStarted(payload);
      isFullScreen && exitFullScreen();
      error.type !== errorTypeString.invalidUserError && submitQuizLocal();
    }

    // eslint-disable-next-line
  }, [errorLevel]);

  // this effect runs when we get quiz result
  useEffect(() => {
    if (quizResult) {
      const payload = {
        isQuizStarted: false
      };
      setIsQuizStarted(payload);
      isFullScreen && exitFullScreen();
    }
    // eslint-disable-next-line
  }, quizResult);

  // sets updated value to isQuizStartedRef, this is used in event listener where stale value is stored
  useEffect(() => {
    isQuizStartedRef.current = isQuizStarted;
  }, [isQuizStarted]);

  return (
    <Container ref={ContainerRef}>
      {loading && <LoadingComponent />}
      {!isQuizStarted ? (
        <MessageDisplayView
          submitAction={() => getQuestionListLocal()}
          error={error}
          errorLevel={errorLevel}
          quizResult={quizResult}
        />
      ) : (
        <QuizExamView
          remainingTime={quizDetail.remainingTime}
          totalDuration={quizDetail.remainingTime}
          setFullScreen={setFullScreen}
          clearError={clearError}
        />
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  loading: state.quizExam.loading,
  accessToken: state.quizExam.accessToken,
  quizDetail: state.quizExam.quizDetail,
  questionList: state.quizExam.questionList,
  error: state.quizExam.error,
  quizResult: state.quizExam.quizResult,
  isQuizStarted: state.quizExam.isQuizStarted,
  errorLevel: state.quizExam.errorLevel
});

const mapDispatchToProps = (dispatch) => ({
  getCredential: (payload) => dispatch(QuizExamCreators.getCredential(payload)),
  getQuizDetail: () => dispatch(QuizExamCreators.getQuizDetail()),
  getQuestionList: (payload) => dispatch(QuizExamCreators.getQuestionList(payload)),
  setError: (payload) => dispatch(QuizExamCreators.setError(payload)),
  submitQuiz: (payload) => dispatch(QuizExamCreators.submitQuiz(payload)),
  setIsQuizStarted: (payload) => dispatch(QuizExamCreators.setIsQuizStarted(payload)),
  startQuiz: (payload) => dispatch(QuizExamCreators.startQuiz(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
