import styled from 'styled-components';

import { textColors, fontWeight, fontSize, accentColor, backgroundColor, shadow } from '../../shared/constants';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  dispaly: flex;
  flex-direction: column;
  padding: 2.5rem;
`;

export const Header = styled.div`
  width: 100%;
  height: 3rem;

  & > p {
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.mediumDefault};
    color: ${textColors.textGray};
  }
`;

export const AnswerContainer = styled.div`
  width: 100%;
  height: calc(100% - 3rem);
  display: flex;
  flex-direction: column;
`;

export const AnswerValueContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  overflow-y: scroll;
`;

export const AnswerItem = styled.div`
  width: 100%;
  maring: 1rem 0;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    height: 4rem;
    width: 4rem;

    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    color: ${(props) => {
      if (props.isSelected === 'true') {
        return `${textColors.textLight};`;
      }
      return `${textColors.textDark};`;
    }};

    font-size: ${fontSize.mediumDefault};

    background-color: ${(props) => {
      if (props.isSelected === 'true') {
        return accentColor.sub;
      }
      return backgroundColor.mediumLight;
    }};
  }

  & > div {
    width: calc(100% - 2rem);
    padding: 2rem 1.5rem;
    background-color: ${(props) => {
      if (props.isSelected === 'true') {
        return backgroundColor.selectedOption;
      }
      return backgroundColor.light;
    }};
    box-shadow: ${shadow.dark};

    border: ${(props) => {
      if (props.isSelected === 'true') {
        return 'solid 1px #9a569f';
      }

      return '';
    }};

    color: ${textColors.textDark};
    font-size: ${fontSize.mediumDefault};
    border-radius: 0.5rem;
  }
`;

export const DecisionContainer = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem;
`;

export const ButtonContainer = styled.div``;
