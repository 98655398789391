export const timeStringFormat = (time) => {
  if (!time || typeof time !== 'string') return undefined;

  const timeSplitArray = time.split(':');

  if (timeSplitArray.length > 2) return undefined;

  const minute = parseInt(timeSplitArray[0]);
  const second = parseInt(timeSplitArray[1]);

  if (second > 59) return undefined;

  return {
    minute,
    second
  };
};
