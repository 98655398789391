export const fontSize = {
  titleHuge: '4.5rem',
  titleSize: '3.5rem',
  titleMedium: '2.8rem',
  titleMediumSmall: '2.6rem',
  titleSmall: '2.5rem',
  titleVerySmall: '2.1rem',
  mediumDefault: '1.5rem',
  title: '1.8rem',

  smallDefault: '1.3rem',
  default: '1rem',
  mediumSmall: '0.85rem',
  small: '0.7rem'
};

export const fontWeight = {
  light: 300,
  medium: 500,
  bold: 700
};
