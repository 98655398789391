import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  quizStatus: null,
  remainingTime: null,
  totalDuration: null,

  //------------------------------
  email: '',
  fullName: '',
  accessToken: null,
  quizDetail: null,
  questionList: [],
  selectedQuestion: null,
  selectedQuestionIndex: 0,
  questionListLength: 0,
  quizResult: null,
  isQuizStarted: false,
  errorLevel: null
};

const setIsQuizStarted = (state = INITIAL_STATE, action) => {
  const { isQuizStarted } = action.payload;

  return {
    ...state,
    isQuizStarted
  };
};

const startQuizStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const startQuizSuccess = (state = INITIAL_STATE, action) => {
  const { isQuizStarted } = action.payload;
  return {
    ...state,
    loading: false,
    isQuizStarted
  };
};

const startQuizFailure = (state = INITIAL_STATE, action) => {
  const { error, errorLevel } = action.payload;
  return {
    ...state,
    loading: false,
    error,
    errorLevel
  };
};

const getCredentialStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getCredentialSuccess = (state = INITIAL_STATE, action) => {
  const { email, fullName, accessToken } = action.payload;
  return {
    ...state,
    loading: false,
    email,
    fullName,
    accessToken
  };
};

const getCredentialFailure = (state = INITIAL_STATE, action) => {
  const { error, errorLevel } = action.payload;

  return {
    ...state,
    loading: false,
    error,
    errorLevel
  };
};

const getQuizDetailStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getQuizDetailSuccess = (state = INITIAL_STATE, action) => {
  const { quizDetail } = action.payload;
  return {
    ...state,
    loading: false,
    quizDetail
  };
};

const getQuizDetailFailure = (state = INITIAL_STATE, action) => {
  /* const { error } = action.payload;
   */
  return {
    ...state,
    loading: false
  };
};

const getQuestionListStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  questionList: []
});

const getQuestionListSuccess = (state = INITIAL_STATE, action) => {
  const { questionList } = action.payload;

  return {
    ...state,
    loading: false,
    questionList,
    selectedQuestion: questionList[0],
    questionListLength: questionList.length
  };
};

const getQuestionListFailure = (state = INITIAL_STATE, action) => {
  /*  const { error } = action.payload; */
  return {
    ...state,
    questionList: [],
    loading: false,
    selectedQuestion: null
  };
};

const saveResponseStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true
  };
};

const saveResponseSuccess = (state = INITIAL_STATE, action) => {
  const { selectedQuestion, selectedQuestionIndex, questionList } = action.payload;
  return {
    ...state,
    loading: false,
    selectedQuestion: selectedQuestion ? selectedQuestion : state.selectedQuestion,
    selectedQuestionIndex: !isNaN(selectedQuestionIndex) ? selectedQuestionIndex : state.selectedQuestionIndex,
    questionList: questionList ? questionList : state.questionList
  };
};

const saveResponseFailure = (state = INITIAL_STATE, action) => {
  /*   const { error } = action.payload; */

  return {
    ...state,
    loading: false
  };
};

const submitQuizStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
    quizResult: null
  };
};

const submitQuizSuccess = (state = INITIAL_STATE, action) => {
  const { quizResult } = action.payload;
  return {
    ...state,
    loading: false,
    quizResult
  };
};

const submitQuizFailure = (state = INITIAL_STATE, action) => {
  /* const { error } = action.payload; */
  return {
    ...state,
    loading: false,
    quizResult: null
  };
};

const onChangeQuestionToSelected = (state = INITIAL_STATE, action) => {
  const { selectedQuestion, selectedQuestionIndex, questionList } = action.payload;
  return {
    ...state,
    selectedQuestion: selectedQuestion ? selectedQuestion : state.selectedQuestion,
    selectedQuestionIndex: !isNaN(selectedQuestionIndex) ? selectedQuestionIndex : state.selectedQuestionIndex,
    questionList: questionList ? questionList : state.questionList
  };
};

const setError = (state = INITIAL_STATE, action) => {
  const { error, errorLevel } = action.payload;
  return {
    ...state,
    error,
    errorLevel
  };
};

const HANDLERS = {
  [Types.GET_CREDENTIAL_START]: getCredentialStart,
  [Types.GET_CREDENTIAL_SUCCESS]: getCredentialSuccess,
  [Types.GET_CREDENTIAL_FAILURE]: getCredentialFailure,

  [Types.GET_QUIZ_DETAIL_START]: getQuizDetailStart,
  [Types.GET_QUIZ_DETAIL_SUCCESS]: getQuizDetailSuccess,
  [Types.GET_QUIZ_DETAIL_FAILURE]: getQuizDetailFailure,

  [Types.GET_QUESTION_LIST_START]: getQuestionListStart,
  [Types.GET_QUESTION_LIST_SUCCESS]: getQuestionListSuccess,
  [Types.GET_QUESTION_LIST_FAILURE]: getQuestionListFailure,

  [Types.SAVE_RESPONSE_START]: saveResponseStart,
  [Types.SAVE_RESPONSE_SUCCESS]: saveResponseSuccess,
  [Types.SAVE_RESPONSE_FAILURE]: saveResponseFailure,

  [Types.SUBMIT_QUIZ_START]: submitQuizStart,
  [Types.SUBMIT_QUIZ_SUCCESS]: submitQuizSuccess,
  [Types.SUBMIT_QUIZ_FAILURE]: submitQuizFailure,

  [Types.ON_CHANGE_QUESTION_TO_SELECTED]: onChangeQuestionToSelected,
  [Types.SET_ERROR]: setError,
  [Types.SET_IS_QUIZ_STARTED]: setIsQuizStarted,

  [Types.START_QUIZ_START]: startQuizStart,
  [Types.START_QUIZ_SUCCESS]: startQuizSuccess,
  [Types.START_QUIZ_FAILURE]: startQuizFailure
};

export const quizExamReducer = createReducer(INITIAL_STATE, HANDLERS);
