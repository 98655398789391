import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { errorLevelString, errorTypeString } from '../../../shared/constants';

import { WebService } from '../../../services/axios/webServices';
import { setAccessTokenToHeader } from '../../../services/axios/helper';
import { apiEndPoints } from '../../../services/axios/endPoints';

export function* getCredentialSaga(action) {
  try {
    yield put(Creators.getCredentialStart());

    const { verificationPin } = action.payload;

    const fullURL = yield `${apiEndPoints.loginAPI}?verificationPin=${verificationPin}`;

    const response = yield WebService.post(fullURL);

    if (response.status >= 200 && response.status < 300) {
      const {
        email,
        fullName,
        token: { AccessToken }
      } = response.data.data;

      setAccessTokenToHeader(AccessToken);

      const payload = {
        email,
        fullName,
        accessToken: AccessToken
      };

      yield put(Creators.getCredentialSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    /*     const { response } = error; */

    const payload = {
      error: {
        type: errorTypeString.invalidUserError
      },
      errorLevel: errorLevelString.major
    };
    yield put(Creators.getCredentialFailure(payload));
  }
}

export function* getQuizDetailSaga() {
  try {
    yield put(Creators.getQuizDetailStart());
    const fullURL = yield `${apiEndPoints.quizDetailAPI}`;

    const response = yield WebService.get(fullURL);

    if (response.status >= 200 && response.status < 300) {
      const quizDetail = response.data.data[0];

      const payload = {
        quizDetail
      };

      yield put(Creators.getQuizDetailSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    const payload = {
      error
    };

    yield put(Creators.getQuizDetailFailure(payload));
  }
}

export function* getQuestionListSaga(action) {
  try {
    yield put(Creators.getQuestionListStart());

    const { quizId } = action.payload;

    const fullURL = yield `${apiEndPoints.questionListAPI}`;

    const response = yield WebService.get(fullURL, {
      params: {
        quizId
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const { questions: questionList } = response.data.data;

      const payload = {
        questionList
      };

      yield put(Creators.getQuestionListSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    const payload = {
      error
    };
    yield put(Creators.getQuestionListFailure(payload));
  }
}

export function* startQuizSaga(action) {
  try {
    yield put(Creators.startQuizStart());

    const { quizId, remainingTime } = action.payload;

    const fullURL = yield `${apiEndPoints.startQuizAPI}`;

    const dataToApi = {
      quizId,
      remainingTime
    };

    const response = yield WebService.post(fullURL, dataToApi);

    if (response.status >= 200 && response.status < 300) {
      const payload = {
        isQuizStarted: true
      };

      yield put(Creators.startQuizSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    // major error
    const payload = {
      error: {
        type: errorTypeString.invalidQuizError
      },
      errorLevel: errorLevelString.major
    };

    yield put(Creators.startQuizFailure(payload));
  }
}

export function* saveTimeProgressSaga(action) {
  try {
    //yield put(Creators.saveTimeProgressStart());

    const { remainingTime, quizId, questionId } = action.payload;

    const fullURL = yield `${apiEndPoints.submitAnswerAPI}`;

    const dataToApi = {
      questionId,
      quizId,
      answer: null,
      remainingTime
    };

    const response = yield WebService.post(fullURL, dataToApi);

    if (response.status >= 200 && response.status < 300) {
      //yield put(Creators.saveTimeProgressSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    // here dont dispatch the error, but show toast messages to tell user about connection and server out of sync
    /* const payload = {
      error
    };

    yield put(Creators.saveTimeProgressFailure(payload)); */
  }
}

export function* saveResponseSaga(action) {
  try {
    yield put(Creators.saveResponseStart());

    const {
      answer,
      quizId,
      oldSelectedQuestion,
      selectedQuestion,
      selectedQuestionIndex,
      questionList,
      remainingTime
    } = action.payload;

    const fullURL = yield `${apiEndPoints.submitAnswerAPI}`;

    const dataToApi = {
      questionId: oldSelectedQuestion[`questionID`],
      quizId,
      answer,
      remainingTime
    };

    const response = yield WebService.post(fullURL, dataToApi);

    if (response.status >= 200 && response.status < 300) {
      const payload = {
        selectedQuestion,
        selectedQuestionIndex,
        questionList
      };

      yield put(Creators.saveResponseSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    const payload = {
      error
    };

    yield put(Creators.saveResponseFailure(payload));
  }
}

export function* submitQuizSaga(action) {
  try {
    yield put(Creators.submitQuizStart());

    const { quizId, remainingTime } = action.payload;

    const fullURL = yield `${apiEndPoints.validateAnswerAPI}`;

    const dataToApi = {
      quizId,
      remainingTime
    };

    const response = yield WebService.post(fullURL, dataToApi);
    if (response.status >= 200 && response.status < 300) {
      const quizResult = response.data.data;

      const payload = {
        quizResult
      };
      yield put(Creators.submitQuizSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    const payload = {
      error
    };

    yield put(Creators.submitQuizFailure(payload));
  }
}
