import { useState, useEffect } from 'react';

const useScreenVisibility = () => {
  const [isScreenVisible, setIsScreenVisible] = useState(true);

  let hidden, visibilityChange;
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  useEffect(() => {
    window.addEventListener(
      visibilityChange,
      () => {
        if (!(isScreenVisible === !document[hidden])) setIsScreenVisible(!document[hidden]);
      },
      false
    );
    window.addEventListener(
      'focus',
      () => {
        if (!isScreenVisible) setIsScreenVisible(true);
      },
      false
    );
    window.addEventListener(
      'blur',
      () => {
        if (isScreenVisible) setIsScreenVisible(false);
      },
      false
    );
  }, [hidden, visibilityChange, isScreenVisible]);

  return isScreenVisible;
};

export default useScreenVisibility;
