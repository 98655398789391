/*
 * sagas.js file for store
 * Author:
 * 1. Shubham Kamath
 * @copyright of Panorbit Services LLP.
 */

import { takeLatest } from 'redux-saga/effects';

import { Types } from './actions';

import {
  getQuestionListSaga,
  saveResponseSaga,
  submitQuizSaga,
  saveTimeProgressSaga,
  getCredentialSaga,
  getQuizDetailSaga,
  startQuizSaga
} from '../views/QuizExamView/store';

export function* watchAuth() {
  yield takeLatest(Types.SAVE_RESPONSE, saveResponseSaga);
  yield takeLatest(Types.SUBMIT_QUIZ, submitQuizSaga);
  yield takeLatest(Types.SAVE_TIME_PROGRESS, saveTimeProgressSaga);

  //---------------------------------------------------------
  yield takeLatest(Types.GET_CREDENTIAL, getCredentialSaga);
  yield takeLatest(Types.GET_QUIZ_DETAIL, getQuizDetailSaga);
  yield takeLatest(Types.GET_QUESTION_LIST, getQuestionListSaga);

  yield takeLatest(Types.START_QUIZ, startQuizSaga);
}
