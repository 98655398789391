import React from 'react';
import styled from 'styled-components';

import { textColors, backgroundColor, fontSize, fontWeight } from '../../shared/constants';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;

  overflow-y: scroll;

  & > * {
    color: ${textColors.textDark};
    font-size: ${fontSize.mediumDefault};
    font-weight: ${fontWeight.bold};

    margin-bottom: 2rem;
  }
`;

const QuestionImage = styled.img`
  width: 100%;
`;

const QuestionContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  & > * {
    color: ${textColors.textDark};
    font-size: ${fontSize.mediumDefault};
    font-weight: ${fontWeight.bold};
  }
`;

const QuestionIndicator = styled.span`
  width: 20rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.2rem;
  background-color: ${backgroundColor.dark};
  color: ${textColors.textLight};
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1.5rem;
`;

class QuizExamQuestion extends React.Component {
  formatText = (question) => {
    if (question) {
      const questionArray = question.split('\n');

      return questionArray.map((item, index) => {
        return (
          <span key={index}>
            {item}
            {index + 1 !== questionArray.length ? <br /> : null}
          </span>
        );
      });
    }
  };

  render() {
    const { question, selectedQuestionIndex, questionListLength, selectedQuestion } = this.props;
    const { imageUrl } = selectedQuestion;

    return (
      <Container>
        <QuestionIndicator>{`Question ${selectedQuestionIndex + 1} of ${questionListLength}`}</QuestionIndicator>
        {question && <QuestionContainer>{this.formatText(question)}</QuestionContainer>}
        {imageUrl && <QuestionImage src={imageUrl} alt={question} />}
      </Container>
    );
  }
}

export default QuizExamQuestion;
