import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Creators } from './store/action';

import {
  Container,
  Header,
  HeaderTextContainer,
  HeaderText,
  HeaderTextSub,
  SubmitButtonContainer,
  BodyContainer,
  ListContainer,
  QuestionContainer,
  AnswerContainer,
  SubmitButton,
  TimeContainer,
  TimeItem,
  PopUp
} from './QuizExam.styles';

import ButtonComponent from '../../shared/components/ButtonComponent/Button.component';

import QuizExamList from './QuizExamList';
import QuizExamQuestion from './QuizExamQuestion';
import QuizExamAnswer from './QuizExamAnswer';

import useInterval from '../../shared/hooks/useInterval';
import { timeStringFormat } from '../../services/utility/timeFormat';
import { errorLevelString, errorTypeString } from '../../shared/constants';

const {
  Container: ContainerPopUp,
  Warning: {
    Container: WarningContainer,
    Message: WarningMessage,
    Info: {
      Container: WarningInfoContainer,
      Main: WarningInfoMain,
      Title: WarningInfoTitle,
      Action: { Container: WarningActionContainer, Button: WarningActionButton }
    }
  }
} = PopUp;

const QuizExamView = (props) => {
  // prop methods
  const { submitQuiz, saveResponse, setFullScreen, clearError } = props;

  //props variables
  const {
    questionList,
    selectedQuestion,
    selectedQuestionIndex,
    questionListLength,
    loading,
    remainingTime,
    totalDuration,
    quizDetail,
    error,
    errorLevel
  } = props;

  const [remainingMinuteLocal, setRemainingMinuteLocal] = useState(0);
  const [remainingSecondLocal, setRemainingSecondLocal] = useState(59);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const { quizId, title: quizTitle } = quizDetail;

  const question = selectedQuestion?.question;
  const ansOption = selectedQuestion?.ansOption;
  const questionType = selectedQuestion?.questionType;
  const questionID = selectedQuestion?.questionID;
  const userAnswer = selectedQuestion?.userAnswer;

  const submitCurrentQuiz = () => {
    let payload = {
      quizId
    };

    submitQuiz(payload);
  };

  const moveForward = (userAnswer) => {
    let tempQuestionList = questionList.map((question, index) => {
      if (index === selectedQuestionIndex) {
        return {
          ...question,
          questionSubmitted: !!userAnswer.length,
          userAnswer
        };
      }

      return question;
    });

    if (selectedQuestionIndex < questionListLength - 1) {
      const tempIndex = selectedQuestionIndex + 1;
      const payload = {
        quizId,
        oldSelectedQuestion: selectedQuestion,
        selectedQuestion: questionList[tempIndex],
        selectedQuestionIndex: tempIndex,
        questionList: tempQuestionList,
        answer: userAnswer,
        remainingTime: `${remainingMinuteLocal}:${remainingSecondLocal}`
      };

      saveResponse(payload);
    } else {
      const payload = {
        quizId: quizId,
        oldSelectedQuestion: selectedQuestion,
        selectedQuestion: questionList[selectedQuestionIndex],
        selectedQuestionIndex,
        questionList: tempQuestionList,
        answer: userAnswer,
        remainingTime: `${remainingMinuteLocal}:${remainingSecondLocal}`
      };

      saveResponse(payload);
    }
  };

  useInterval(() => {
    progressPercentage !== 100 &&
      !loading &&
      setRemainingSecondLocal(remainingSecondLocal === 0 ? 59 : remainingSecondLocal - 1);
    remainingSecondLocal === 0 && setRemainingMinuteLocal(remainingMinuteLocal - 1);
  }, 1000);

  useEffect(() => {
    const time = timeStringFormat(remainingTime);

    const getTimeSecond = (second) => {
      if (!second || isNaN(second)) return 0;
      if (time.second === 0) return 59;
      return time.second;
    };

    if (time) {
      const remainingMinute = time.second === 0 ? time.minute - 1 : time.minute;
      const remainingSecond = getTimeSecond(time.second);

      setRemainingMinuteLocal(remainingMinute);
      setRemainingSecondLocal(remainingSecond);
    }
  }, [remainingTime, totalDuration]);

  useEffect(() => {
    if (totalDuration) {
      const totalDurationInteger = parseInt(totalDuration);

      setProgressPercentage(
        totalDurationInteger === remainingMinuteLocal
          ? 0
          : ((totalDurationInteger - remainingMinuteLocal - 1) / totalDurationInteger) * 100
      );
    }
  }, [remainingMinuteLocal, totalDuration]);

  useEffect(() => {
    if (progressPercentage === 100) submitCurrentQuiz();
    // eslint-disable-next-line
  }, [progressPercentage]);

  const _renderErrorPopUp = (error) => {
    const { type } = error;

    let errorStringToDisplay = '';

    if (errorTypeString.focusLostError === type) {
      errorStringToDisplay = 'Screen Changed';
    }

    if (errorTypeString.fullScreenError === type) {
      errorStringToDisplay = 'Full Screen closed';
    }

    if (errorTypeString.reloadQuizError === type) {
      errorStringToDisplay = 'Quiz Reloaded';
    }

    return (
      <ContainerPopUp>
        <WarningContainer>
          <WarningMessage>
            <p>Warning</p>
          </WarningMessage>
          <WarningInfoContainer>
            <WarningInfoMain>
              <p>{errorStringToDisplay}</p>
            </WarningInfoMain>
            <WarningInfoTitle>
              <p>One more attempt will submit the Test.</p>
            </WarningInfoTitle>
            <WarningActionContainer>
              <WarningActionButton
                onClick={() => {
                  clearError();
                  (errorTypeString.fullScreenError === type || errorTypeString.reloadQuizError === type) &&
                    setFullScreen();
                }}
              >
                Okay
              </WarningActionButton>
            </WarningActionContainer>
          </WarningInfoContainer>
        </WarningContainer>
      </ContainerPopUp>
    );
  };

  return (
    <Container>
      {error && errorLevel === errorLevelString.minor && _renderErrorPopUp(error)}
      <Header>
        <HeaderTextContainer>
          <HeaderText>{quizTitle}</HeaderText>
          <HeaderTextSub>{`${questionListLength} Questions`}</HeaderTextSub>
        </HeaderTextContainer>
        <TimeContainer>
          {progressPercentage !== 100 && (
            <>
              <TimeItem>
                <p>Time Left:</p>
                <p>{`${remainingMinuteLocal}:${
                  remainingSecondLocal < 10 ? `0${remainingSecondLocal}` : remainingSecondLocal
                }`}</p>
              </TimeItem>
              <TimeItem percentage={progressPercentage}>
                <span />
              </TimeItem>
            </>
          )}
        </TimeContainer>
        <SubmitButtonContainer>
          <SubmitButton>
            <ButtonComponent buttonText='Submit' buttonClick={() => submitCurrentQuiz()} />
          </SubmitButton>
        </SubmitButtonContainer>
      </Header>
      <BodyContainer>
        <ListContainer>
          <QuizExamList />
        </ListContainer>
        <QuestionContainer>
          <QuizExamQuestion
            question={question}
            selectedQuestionIndex={selectedQuestionIndex}
            questionListLength={questionListLength}
            selectedQuestion={selectedQuestion}
          />
        </QuestionContainer>
        <AnswerContainer>
          <QuizExamAnswer
            question={question}
            answerList={ansOption}
            questionType={questionType}
            questionID={questionID}
            userAnswer={userAnswer}
            moveForward={moveForward}
            selectedQuestionIndex={selectedQuestionIndex}
            questionListLength={questionListLength}
          />
        </AnswerContainer>
      </BodyContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  loading: state.quizExam.loading,
  questionList: state.quizExam.questionList,
  selectedQuestionIndex: state.quizExam.selectedQuestionIndex,
  selectedQuestion: state.quizExam.selectedQuestion,
  questionListLength: state.quizExam.questionListLength,

  quizDetail: state.quizExam.quizDetail,
  error: state.quizExam.error,
  errorLevel: state.quizExam.errorLevel
});

const mapDispatchToProps = (dispatch) => ({
  getQuestionList: (payload) => dispatch(Creators.getQuestionList(payload)),
  saveResponse: (payload) => dispatch(Creators.saveResponse(payload)),
  saveResponseSuccess: (payload) => dispatch(Creators.saveResponseSuccess(payload)),
  submitQuiz: (payload) => dispatch(Creators.submitQuiz(payload)),
  saveTimeProgress: (payload) => dispatch(Creators.saveTimeProgress(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizExamView);
