/*
 * reducers.js file for store
 * Author:
 * 1. Shubham Kamath
 *
 * @copyright of Panorbit Services LLP.
 */

import { combineReducers } from 'redux';

import { quizExamReducer } from '../views/QuizExamView/store';

const rootReducer = combineReducers({
  quizExam: quizExamReducer
});

export default rootReducer;
