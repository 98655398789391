/*
 * actions.js file for store
 * Author:
 * 1. Shubham Kamath
 * @copyright of Panorbit Services LLP.
 */

import { Types as QuizExamTypes, Creators as QuizExamCreators } from '../views/QuizExamView/store';

const Types = {
  ...QuizExamTypes
};

const Creators = {
  ...QuizExamCreators
};

export { Types, Creators };
